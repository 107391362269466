import { Link } from 'react-router-dom';
import React from 'react'
import "./Header.css";

const TITLE = process.env.REACT_APP_APP_TITLE;

const Header = () => {
    return (
        <div className = "home">
        <Link to="/" className="website-name"><h3>{TITLE}</h3></Link>
        <nav>
            <Link to="/">Home</Link>
            <Link to="/browse">Browse</Link>
            <Link to="/date-converter">Date Converter</Link>
            <Link to="/about">About</Link>
            {/* <Link to="/feedback">Feedback</Link>
            <Link to="/contribute">Contribute</Link> */}
        </nav>
        </div>
    )
}

export default Header
