import React, { useState, useEffect } from 'react';
import { adToBs, bsToAd } from '@sbmdkl/nepali-date-converter';
import { BS_MONTHS, AD_MONTHS, BS_DAYS, AD_DAYS, convertToNepaliDigits, BS_CALENDAR_DATA } from './NepaliCalenderData';
import './DateConverter.css';

const DateConverter = () => {
    const [conversionType, setConversionType] = useState('BS_TO_AD');
    const [selectedDate, setSelectedDate] = useState({
        year: '',
        month: '',
        day: ''
    });
    const [convertedDate, setConvertedDate] = useState({
        year: '',
        month: '',
        day: ''
    });
    const [calendar, setCalendar] = useState([]);

    const getDaysInMonth = (year, month, isBS) => {
        if (isBS) {
            // Get days from BS calendar data
            if (BS_CALENDAR_DATA[year]) {
                return BS_CALENDAR_DATA[year][month - 1];
            }
            return 30; // fallback value if year not found
        } else {
            // Days in English months remain the same
            const adDays = {
                1: 31,
                2: isLeapYear(year) ? 29 : 28,
                3: 31,
                4: 30,
                5: 31,
                6: 30,
                7: 31,
                8: 31,
                9: 30,
                10: 31,
                11: 30,
                12: 31
            };
            return adDays[month] || 31;
        }
    };

    const getDaysArray = (year, month, conversionType) => {
        if (!year || !month) return [];
        const daysInMonth = getDaysInMonth(parseInt(year), parseInt(month), conversionType === 'BS_TO_AD');
        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    };

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    };

    const getConvertedDay = (year, month, day, isBS) => {
        if (!year || !month || !day) return '';
        try {
            const dateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            const convertedDate = isBS ? bsToAd(dateString) : adToBs(dateString);
            return convertedDate.split('-')[2];
        } catch (error) {
            return '';
        }
    };

    const getConvertedDayWithoutZero = (year, month, day, isBS) => {
        var _day = getConvertedDay(year, month, day, isBS);
        return _day.startsWith('0') ? _day.substring(1) : _day;
    }

    // Generate year ranges
    const bsYears = Array.from({ length: 2099 - 1978 + 1 }, (_, i) => 1978 + i);
    const adYears = Array.from({ length: 2040 - 1921 + 1 }, (_, i) => 1921 + i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 32 }, (_, i) => i + 1);

    // Set today's date on initial load and when conversion type changes
    // Replace the existing useEffect for setting today's date with this:
    useEffect(() => {
        const today = new Date();
        const todayAD = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

        if (conversionType === 'AD_TO_BS') {
            setSelectedDate({
                year: today.getFullYear(),
                month: today.getMonth() + 1,
                day: today.getDate()
            });
        } else {
            try {
                // Convert today's AD date to BS
                const todayBs = adToBs(todayAD);
                const [year, month, day] = todayBs.split('-');
                setSelectedDate({
                    year: parseInt(year),
                    month: parseInt(month),
                    day: parseInt(day)
                });
            } catch (error) {
                console.error('Error converting today\'s date to BS:', error);
            }
        }
    }, [conversionType]);

    useEffect(() => {
        if (selectedDate.year && selectedDate.month) {
            const maxDays = getDaysInMonth(
                parseInt(selectedDate.year),
                parseInt(selectedDate.month),
                conversionType === 'BS_TO_AD'
            );

            if (parseInt(selectedDate.day) > maxDays) {
                setSelectedDate(prev => ({ ...prev, day: maxDays }));
            }
        }
    }, [selectedDate.year, selectedDate.month, conversionType]);

    const convertDate = () => {
        if (selectedDate.year && selectedDate.month && selectedDate.day) {
            try {
                const dateString = `${selectedDate.year}-${String(selectedDate.month).padStart(2, '0')}-${String(selectedDate.day).padStart(2, '0')}`;

                if (conversionType === 'BS_TO_AD') {
                    const adDate = bsToAd(dateString);
                    const [year, month, day] = adDate.split('-');
                    setConvertedDate({ year, month, day });
                } else {
                    const bsDate = adToBs(dateString);
                    const [year, month, day] = bsDate.split('-');
                    setConvertedDate({ year, month, day });
                }
            } catch (error) {
                console.error('Date conversion error:', error);
            }
        }
    };

    const generateCalendar = () => {
        if (!selectedDate.year || !selectedDate.month) return;

        // Get the correct number of days based on conversion type
        let daysInMonth;
        if (conversionType === 'BS_TO_AD') {
            // For BS dates, get days from BS_CALENDAR_DATA
            daysInMonth = BS_CALENDAR_DATA[selectedDate.year]
                ? BS_CALENDAR_DATA[selectedDate.year][selectedDate.month - 1]
                : 30;
        } else {
            // For AD dates, calculate normally
            daysInMonth = new Date(selectedDate.year, selectedDate.month, 0).getDate();
        }

        const firstDay = new Date(
            conversionType === 'BS_TO_AD'
                ? bsToAd(`${selectedDate.year}-${selectedDate.month}-01`)
                : `${selectedDate.year}-${selectedDate.month}-01`
        ).getDay();

        const calendarDays = [];
        let dayCounter = 1;

        for (let i = 0; i < 6; i++) {
            const week = [];
            for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDay) {
                    week.push('');
                } else if (dayCounter <= daysInMonth) {
                    week.push(dayCounter);
                    dayCounter++;
                } else {
                    week.push('');
                }
            }
            //if all the elements in week is '', then continue
            if (week.every(day => day === '')) continue;
            calendarDays.push(week);
        }
        setCalendar(calendarDays);
    };

    useEffect(() => {
        convertDate();
        generateCalendar();
    }, [selectedDate]);

    // add a text section that says "Convert BS to AD" or "Convert AD to BS"
    return (
        <div className="converter-container">
            <div className="converter-title">
                <h1>Nepali Date Converter</h1>
                <p>Convert dates between Bikram Sambat (BS) and Anno Domini (AD) calendars or vice-versa</p>

            </div>
            <div className="radio-group">
                <label className="radio-label">
                    <input
                        className='radio-input-1'
                        type="radio"
                        value="BS_TO_AD"
                        checked={conversionType === 'BS_TO_AD'}
                        onChange={(e) => setConversionType(e.target.value)}
                    />
                    Convert BS to AD
                </label>
                <label className="radio-label">
                    <input
                        className='radio-input-1'
                        type="radio"
                        value="AD_TO_BS"
                        checked={conversionType === 'AD_TO_BS'}
                        onChange={(e) => setConversionType(e.target.value)}
                    />
                    Convert AD to BS
                </label>
            </div>

            <div className="date-selectors">
                <select
                    className="select-input"
                    value={selectedDate.year}
                    onChange={(e) => setSelectedDate(prev => ({ ...prev, year: e.target.value }))}
                >
                    {(conversionType === 'BS_TO_AD' ? bsYears : adYears).map(year => (
                        <option key={year} value={year}> {conversionType === 'BS_TO_AD' ? convertToNepaliDigits(year) : year}</option>
                    ))}
                </select>

                <select
                    className="select-input"
                    value={selectedDate.month}
                    onChange={(e) => setSelectedDate(prev => ({ ...prev, month: e.target.value }))}
                >
                    {(conversionType === 'BS_TO_AD' ? BS_MONTHS : AD_MONTHS).map((month, index) => (
                        <option key={month} value={index + 1}>{month}</option>
                    ))}
                </select>

                <select
                    className="select-input"
                    value={selectedDate.day}
                    onChange={(e) => setSelectedDate(prev => ({ ...prev, day: e.target.value }))}
                >
                    {getDaysArray(selectedDate.year, selectedDate.month, conversionType).map(day => (
                        <option key={day} value={day}>
                            {conversionType === 'BS_TO_AD' ? convertToNepaliDigits(day) : day}
                        </option>
                    ))}
                </select>
            </div>

            {convertedDate.year && (
                <div className="converted-date">
                    <h3>Converted Date ({conversionType === 'BS_TO_AD' ? 'AD' : 'BS'}):</h3>
                    <p>
                        {(conversionType === 'BS_TO_AD' ? AD_DAYS : BS_DAYS)[
                            new Date(
                                convertedDate.year,
                                convertedDate.month - 1,
                                convertedDate.day
                            ).getDay()
                        ]}, {" "}
                        {conversionType === 'BS_TO_AD'
                            ? `${convertedDate.year}-${AD_MONTHS[convertedDate.month - 1]}, ${convertedDate.day}`
                            : `${convertToNepaliDigits(convertedDate.year)}-${BS_MONTHS[convertedDate.month - 1]}, ${convertToNepaliDigits(convertedDate.day)}`
                        }
                    </p>
                </div>
            )}

            <div className="calendar-container">
                <div className="calendar-header-main">
                    {conversionType === 'BS_TO_AD'
                        ? `${BS_MONTHS[parseInt(selectedDate.month) - 1]} ${convertToNepaliDigits(selectedDate.year)}`
                        : `${AD_MONTHS[parseInt(selectedDate.month) - 1]} ${selectedDate.year}`
                    }
                </div>
                <div className="calendar-grid">
                    {(conversionType === 'BS_TO_AD' ? BS_DAYS : AD_DAYS).map(day => (
                        <div key={day} className="calendar-header">{day}</div>
                    ))}

                    {calendar.map((week, weekIndex) => (
                        week.map((day, dayIndex) => (
                            <div
                                key={`${weekIndex}-${dayIndex}`}
                                className={`calendar-day ${day === parseInt(selectedDate.day) ? 'selected' : ''
                                    }`}
                            >
                                {day && (
                                    <>
                                        <span className="main-day">
                                            {conversionType === 'BS_TO_AD'
                                                ? convertToNepaliDigits(day)
                                                : day}
                                        </span>
                                        <span className="converted-day">
                                            {day && getConvertedDayWithoutZero(
                                                selectedDate.year,
                                                selectedDate.month,
                                                day,
                                                conversionType === 'BS_TO_AD'
                                            )}
                                        </span>
                                    </>
                                )}
                            </div>
                        ))
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DateConverter;